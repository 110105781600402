<template>
  <main id="settings" class="settings main-content">
    <div class="welcome">
      <div class="welcome--box">
        <h1 v-if="!isBrandsArchive">{{ $t("campaigns.title") }}</h1>
        <h1 v-if="isBrandsArchive">{{ $t("campaigns.table.brands.title") }}</h1>
        <h2 v-if="!isBrandsArchive">{{ $t("campaigns.subtitle") }}</h2>
      </div>
      <div>
        <button v-if="!isArchive && !isBrandsArchive" type="button" class="btn big" @click="goToArchive()">
          <span>{{ $t("campaigns.gotoarchive") }}</span>
        </button>
        <button v-if="!isBrandsArchive && !isArchive" type="button" class="btn big" @click="goToBrandsArchive()">
          <span>{{ $t("campaigns.goToBrandsArchive") }}</span>
        </button>
        <button v-if="isArchive || isBrandsArchive" type="button" class="btn big" @click="goToHome()">
          <span>{{ $t("campaigns.gotohome") }}</span>
        </button>
      </div>
    </div>

    <!-- base -->
    <div class="first-row row" v-if="!isArchive && !isBrandsArchive">
      <div class="col full">
        <div class="panel panel__bottom_margin" style="width: 100%">
          <h4>{{ $t("campaigns.table.title.sent") }}</h4>
          <div v-if="data.sent && data.sent.length && !loading.sent" class="campaign--table">
            <div class="campaign--head">
              <span class="campaign--col brand">
                {{ $t("campaigns.table.head.brand") }}
              </span>
              <span class="campaign--col name">
                {{ $t("campaigns.table.head.name") }}
              </span>
              <span class="campaign--col date">
                {{ $t("campaigns.table.head.date") }}
              </span>
              <span class="campaign--col invite">
                {{ $t("campaigns.table.head.date_invite") }}
              </span>
              <span class="campaign--col status action">
                {{ $t("campaigns.table.head.status") }}
              </span>
              <span class="campaign--col detail action">
                {{ $t("campaigns.table.head.detail") }}
              </span>
            </div>
            <div class="campaign--body">
              <div v-for="(s, index) in data.sent" v-bind:key="index" class="campaign--row">
                <span class="campaign--col brand">
                  {{ s.brand }}
                </span>
                <span class="campaign--col name">
                  {{ s.name }}
                </span>
                <span class="campaign--col date">
                  {{ s.start_date | data_custom("DD/MM/YYYY") }} -
                  {{ s.end_date | data_custom("DD/MM/YYYY") }}
                </span>
                <span class="campaign--col invite">
                  {{ s.publish_date | data_custom("DD/MM/YYYY") }}
                </span>
                <span class="campaign--col status action">
                  <span class="status--icon" @click="() => {
                    disableModalButtons = false;
                    campaignSelected = s;
                  }
                    ">
                    <svg viewBox="0 0 486 486">
                      <path
                          d="M485.999 285.485v-84.951l-55.373-13.79c-3.817-12.81-8.856-25.131-15.157-36.324l29.4-49.225-60.046-60.083-48.882 29.401c-11.551-6.231-23.061-11.306-36.681-15.139L285.505 0h-84.949l-13.774 55.374c-12.809 3.852-25.13 8.892-36.681 15.139l-48.862-29.401-60.092 60.087 29.399 49.411c-6.246 11.018-11.305 23.32-15.157 36.134L.35 200.518 0 285.469l55.389 13.79c3.852 13.271 8.907 25.095 15.138 36.666l-29.4 48.848 60.081 60.098 49.692-29.4c10.741 6.231 23.027 11.306 35.855 15.157l13.774 55.356 84.965.015 13.755-55.39c13.331-3.833 25.13-8.907 36.681-15.139l48.862 29.401 60.061-60.064-29.399-48.862c6.301-11.567 11.341-23.225 15.157-36.718l55.388-13.742ZM242.998 332.23c-49.248-.016-89.184-39.956-89.184-88.33 0-50.157 39.92-90.113 89.516-90.113 48.951 0 88.906 39.956 88.906 90.113 0 48.374-39.955 88.33-89.238 88.33Z"/>
                    </svg>
                  </span>
                </span>
                <!--<span class="campaign--col detail action" @click="() => { disableModalButtons = true; campaignSelected = s }">
                  <span class="detail--icon">
                    <span class="dot"></span>
                    <span class="dot"></span>
                    <span class="dot"></span>
                  </span>
                </span>-->
              </div>
            </div>
          </div>
          <div v-if="data.sent && data.sent.length <= 0">
            <p>{{ $t("campaigns.table.noData.sentCampaigns") }}</p>
          </div>
          <loading-spinner :small="true" v-if="loading.sent"></loading-spinner>
        </div>
        <div class="panel panel__bottom_margin" style="width: 100%">
          <h4>{{ $t("campaigns.table.title.current") }}</h4>
          <div v-if="data.current && data.current.length && !loading.current" class="campaign--table">
            <div class="campaign--head">
              <span class="campaign--col brand">
                {{ $t("campaigns.table.head.brand") }}
              </span>
              <span class="campaign--col name">
                {{ $t("campaigns.table.head.name") }}
              </span>
              <span class="campaign--col date">
                {{ $t("campaigns.table.head.date") }}
              </span>
              <span class="campaign--col invite">
                {{ $t("campaigns.table.head.date_invite") }}
              </span>
              <span class="campaign--col status action">
                {{ $t("campaigns.table.head.status") }}
              </span>
              <span class="campaign--col detail action">
                {{ $t("campaigns.table.head.detail") }}
              </span>
            </div>
            <div class="campaign--body">
              <div v-for="(s, index) in data.current" v-bind:key="index" class="campaign--row">
                <span class="campaign--col brand">
                  {{ s.brand }}
                </span>
                <span class="campaign--col name">
                  {{ s.name }}
                </span>
                <span class="campaign--col date">
                  {{ s.start_date | data_custom("DD/MM/YYYY") }} -
                  {{ s.end_date | data_custom("DD/MM/YYYY") }}
                </span>
                <span class="campaign--col invite">
                  {{ s.publish_date | data_custom("DD/MM/YYYY") }}
                </span>
                <span class="campaign--col status action">
                  <span class="status--icon accepted" @click="() => {
                    disableModalButtons = true;
                    campaignSelected = s;
                  }
                    ">
                    <!-- {{ $tc('campaigns.accepted_campaigns', s.optins.length, {count: s.optins.length}) }} -->
                  </span>
                </span>
                <span class="campaign--col detail action" @click="campaignForDetailSelected = s">
                  <span class="detail--icon">
                    <span class="dot"></span>
                    <span class="dot"></span>
                    <span class="dot"></span>
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div v-if="data.current && data.current.length <= 0">
            <p>{{ $t("campaigns.table.noData.otherCampaigns") }}</p>
          </div>
          <loading-spinner :small="true" v-if="loading.current"></loading-spinner>
        </div>
      </div>
    </div>

    <!-- archivio -->
    <div class="first-row row" v-if="isArchive">
      <div class="col full">
        <div class="panel panel__bottom_margin" style="width: 100%">
          <h4>{{ $t("campaigns.table.title.rejected") }}</h4>
          <div v-if="data.rejected && data.rejected.length && !loading.rejected" class="campaign--table">
            <div class="campaign--head">
              <span class="campaign--col brand">
                {{ $t("campaigns.table.head.brand") }}
              </span>
              <span class="campaign--col name">
                {{ $t("campaigns.table.head.name") }}
              </span>
              <span class="campaign--col date">
                {{ $t("campaigns.table.head.date") }}
              </span>
              <span class="campaign--col invite">
                {{ $t("campaigns.table.head.date_invite") }}
              </span>
              <span class="campaign--col status action">
                {{ $t("campaigns.table.head.status") }}
              </span>
              <span class="campaign--col detail action">
                {{ $t("campaigns.table.head.detail") }}
              </span>
            </div>
            <div class="campaign--body">
              <div v-for="(s, index) in data.rejected" v-bind:key="index" class="campaign--row">
                <span class="campaign--col brand">
                  {{ s.brand }}
                </span>
                <span class="campaign--col name">
                  {{ s.name }}
                </span>
                <span class="campaign--col date">
                  {{ s.start_date | data_custom("DD/MM/YYYY") }} -
                  {{ s.end_date | data_custom("DD/MM/YYYY") }}
                </span>
                <span class="campaign--col invite">
                  {{ s.publish_date | data_custom("DD/MM/YYYY") }}
                </span>
                <span class="campaign--col status action">
                  <span class="status--icon" @click="() => {
                    disableModalButtons = false;
                    campaignSelected = s;
                  }
                    ">
                    <svg viewBox="0 0 486 486">
                      <path
                          d="M485.999 285.485v-84.951l-55.373-13.79c-3.817-12.81-8.856-25.131-15.157-36.324l29.4-49.225-60.046-60.083-48.882 29.401c-11.551-6.231-23.061-11.306-36.681-15.139L285.505 0h-84.949l-13.774 55.374c-12.809 3.852-25.13 8.892-36.681 15.139l-48.862-29.401-60.092 60.087 29.399 49.411c-6.246 11.018-11.305 23.32-15.157 36.134L.35 200.518 0 285.469l55.389 13.79c3.852 13.271 8.907 25.095 15.138 36.666l-29.4 48.848 60.081 60.098 49.692-29.4c10.741 6.231 23.027 11.306 35.855 15.157l13.774 55.356 84.965.015 13.755-55.39c13.331-3.833 25.13-8.907 36.681-15.139l48.862 29.401 60.061-60.064-29.399-48.862c6.301-11.567 11.341-23.225 15.157-36.718l55.388-13.742ZM242.998 332.23c-49.248-.016-89.184-39.956-89.184-88.33 0-50.157 39.92-90.113 89.516-90.113 48.951 0 88.906 39.956 88.906 90.113 0 48.374-39.955 88.33-89.238 88.33Z"/>
                    </svg>
                  </span>
                </span>
                <span class="campaign--col detail action" @click="() => {
                  disableModalButtons = true;
                  campaignSelected = s;
                }
                  ">
                  <span class="detail--icon">
                    <span class="dot"></span>
                    <span class="dot"></span>
                    <span class="dot"></span>
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div v-if="data.rejected && data.rejected.length <= 0">
            <p>{{ $t("campaigns.table.noData.otherCampaigns") }}</p>
          </div>
          <loading-spinner :small="true" v-if="loading.rejected"></loading-spinner>
        </div>
        <div class="panel panel__bottom_margin" style="width: 100%">
          <h4>{{ $t("campaigns.table.title.expired") }}</h4>
          <div v-if="data.expired &&
            data.expired.data &&
            data.expired.data.length &&
            !loading.expired
            " class="campaign--table">
            <div class="campaign--head">
              <span class="campaign--col brand">
                {{ $t("campaigns.table.head.brand") }}
              </span>
              <span class="campaign--col name">
                {{ $t("campaigns.table.head.name") }}
              </span>
              <span class="campaign--col date">
                {{ $t("campaigns.table.head.date") }}
              </span>
              <span class="campaign--col invite">
                {{ $t("campaigns.table.head.date_invite") }}
              </span>
              <span class="campaign--col status action">
                {{ $t("campaigns.table.head.status") }}
              </span>
              <span class="campaign--col detail action">
                {{ $t("campaigns.table.head.detail") }}
              </span>
            </div>
            <div class="campaign--body">
              <div v-for="s in data.expired.data" v-bind:key="s.id" class="campaign--row">
                <span class="campaign--col brand">
                  {{ s.brand }}
                </span>
                <span class="campaign--col name">
                  {{ s.name }}
                </span>
                <span class="campaign--col date">
                  {{ s.start_date | data_custom("DD/MM/YYYY") }} -
                  {{ s.end_date | data_custom("DD/MM/YYYY") }}
                </span>
                <span class="campaign--col invite">
                  {{ s.publish_date | data_custom("DD/MM/YYYY") }}
                </span>
                <span class="campaign--col status action">
                  <span class="status--button" @click="() => {
                    disableModalButtons = true;
                    campaignSelected = s;
                  }
                    ">
                    {{
                      $tc("campaigns.in_campaigns", s.optins.length, {
                        count: s.optins.length,
                      })
                    }}
                  </span>
                </span>
                <span class="campaign--col detail action" @click="campaignForDetailSelected = s">
                  <span class="detail--icon">
                    <span class="dot"></span>
                    <span class="dot"></span>
                    <span class="dot"></span>
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div class="campaign--pagination">
            <button v-if="data.expired.page > 1" @click="setPage(data.expired.page - 1, 'expired')"
                    class="control prev">
              <span class="arrow"></span>
            </button>
            <button :class="{ current: p == data.expired.page }" @click="setPage(p, 'expired')"
                    v-for="p in rangePage(data.expired.page, 7, 'expired')" :key="p" class="number">
              {{ p }}
            </button>
            <button v-if="data.expired.page < data.expired.pages" @click="setPage(data.expired.page + 1, 'expired')"
                    class="control next">
              <span class="arrow"></span>
            </button>
          </div>
          <!-- <table v-if="data.expired && data.expired.data && data.expired.data.length && !loading.expired">
            <thead>
            <tr>
              <th>{{ $t('campaigns.table.head.brand') }}</th>
              <th>{{ $t('campaigns.table.head.name') }}</th>
              <th>{{ $t('campaigns.table.head.status') }}</th>
              <th>{{ $t('campaigns.table.head.date') }}</th>
              <th>{{ $t('campaigns.table.head.date_invite') }}</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="s in data.expired.data" v-bind:key="s.id">
              <td>{{ s.brand }}</td>
              <td>{{ s.name }}</td>
              <td>
                <button type="button" class="btn big"
                    @click="() => { disableModalButtons = true; campaignSelected = s }">
                  {{
                    $tc('campaigns.in_campaigns', s.optins.length, {count: s.optins.length})
                  }}
                </button>
              </td>
              <td>{{ s.start_date | data_custom('DD/MM/YYYY') }} - {{
                  s.end_date | data_custom('DD/MM/YYYY')
                }}
              </td>
              <td>{{ s.publish_date | data_custom('DD/MM/YYYY') }}</td>
              <td class="text-center">
                <button type="button" class="btn big" @click="campaignForDetailSelected = s">
                  {{ $t('campaigns.table.detail') }}
                </button>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="pagination">
                <div>
                  <button v-if="data.expired.page > 1"
                      @click="setPage(data.expired.page - 1, 'expired')">
                    &#060;
                  </button>
                  <button :class="{'current': p == data.expired.page }" @click="setPage(p, 'expired')"
                      v-for="p in rangePage(data.expired.page, 7, 'expired')" :key="p">
                    {{ p }}
                  </button>
                  <button v-if="data.expired.page < data.expired.pages"
                      @click="setPage(data.expired.page + 1, 'expired')">
                    &#062;
                  </button>
                </div>
              </td>
            </tr>
            </tbody>
          </table> -->
          <div v-if="data.expired && data.expired.data && data.expired.data.length <= 0
            ">
            <p>{{ $t("campaigns.table.noData.otherCampaigns") }}</p>
          </div>
          <loading-spinner :small="true" v-if="loading.expired"></loading-spinner>
        </div>
      </div>
    </div>

    <!-- archivio brand -->
    <div class="first-row row" v-if="isBrandsArchive">
      <div class="col full">
        <div class="panel panel__bottom_margin" style="width: 100%">
          <h4>{{ $t("campaigns.table.brands.accepted.title") }}</h4>
          <div v-if="brands.length > 0" class="campaign--table">
            <div class="campaign--head">
              <span class="campaign--col brand">
                {{ $t("campaigns.table.brands.accepted.headers.brandName") }}
              </span>
              <span class="campaign--col date">
                {{ $t("campaigns.table.brands.accepted.headers.acceptanceDate") }}
              </span>
              <span class="campaign--col status action">
                {{ $t("campaigns.table.brands.accepted.headers.status") }}
              </span>
            </div>
            <div class="campaign--body">
              <div v-for="(brand, index) in brands" v-bind:key="index" class="campaign--row">
                <span class="campaign--col brand">
                  {{ brand.brand }}
                </span>
                <span class="campaign--col date">
                  {{ brand.created_at | data_custom("DD/MM/YYYY") }}
                </span>
                <span class="campaign--col status action">
                  <span class="status--icon" @click="removeBrand(brand)">
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                         style="width: 1rem; height: 1rem;">
                      <g stroke-width="0"/>
                      <g stroke-linecap="round" stroke-linejoin="round"/>
                      <g>
                        <path
                            d="M20.7457 3.32851C20.3552 2.93798 19.722 2.93798 19.3315 3.32851L12.0371 10.6229L4.74275 3.32851C4.35223 2.93798 3.71906 2.93798 3.32854 3.32851C2.93801 3.71903 2.93801 4.3522 3.32854 4.74272L10.6229 12.0371L3.32856 19.3314C2.93803 19.722 2.93803 20.3551 3.32856 20.7457C3.71908 21.1362 4.35225 21.1362 4.74277 20.7457L12.0371 13.4513L19.3315 20.7457C19.722 21.1362 20.3552 21.1362 20.7457 20.7457C21.1362 20.3551 21.1362 19.722 20.7457 19.3315L13.4513 12.0371L20.7457 4.74272C21.1362 4.3522 21.1362 3.71903 20.7457 3.32851Z"
                            fill="#ed7d31"/>
                      </g>
                    </svg>
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div v-if="brands.length <= 0">
            <p>{{ $t("campaigns.table.noData.brands") }}</p>
          </div>
          <loading-spinner :small="true" v-if="loading.brands"/>
        </div>
      </div>
    </div>

    <!-- dettagli campagna -->
    <campaign-modal @close="campaignForDetailSelected = null" v-if="campaignForDetailSelected"
                    :campaign="campaignForDetailSelected"></campaign-modal>

    <campaign-confirm-modal :disable-buttons="disableModalButtons"
                            @close="handleCampaignConfirmModalClose"
                            v-if="campaignSelected"
                            :campaign="campaignSelected"/>

    <!-- Optin accepted modal -->
    <optin-accepted-modal v-if="acceptedCampaignFetched && isOptinAcceptedModalVisible"
                          :campaign-name="acceptedCampaign.name"
                          :application-ids="acceptedAppIds"
                          :brand-name="acceptedCampaign.brand"
                          @close="isOptinAcceptedModalVisible = false"
                          @autoAcceptNextOptins="addBrandToAutoAcceptAndCloseModal(acceptedCampaign.brand)"/>
  </main>
</template>
<script>
import LoadingSpinner from "../components/common/Loading";
import CampaignModal from "../components/common/modals/CampaignModal";
import CampaignConfirmModal from "../components/common/modals/CampaignConfirmModal";
import OptinAcceptedModal from "@components/common/modals/OptinAcceptedModal.vue";

export default {
  name: "CampaignsPage",
  components: {OptinAcceptedModal, CampaignConfirmModal, CampaignModal, LoadingSpinner},
  props: {
    openOptinAcceptedModal: Boolean,
    emailAcceptedCampaignId: String,
    emailApplicationIds: Array,
  },
  mounted() {
    this.init();

    this.acceptedCampaignId = this.emailAcceptedCampaignId;
    this.acceptedAppIds = this.emailApplicationIds;
    this.isOptinAcceptedModalVisible = this.openOptinAcceptedModal;
  },
  data() {
    return {
      campaignForDetailSelected: null,
      campaignSelected: null,
      disableModalButtons: false,
      loading: {
        sent: false,
        current: false,
        rejected: false,
        expired: false,
        brands: false,
      },
      data: {
        sent: [],
        current: [],
        rejected: [],
        expired: {
          page: 1,
          pages: 0,
          data: [],
          allData: [],
        },
      },
      isArchive: false,
      isBrandsArchive: false,
      acceptedCampaignId: '',
      acceptedCampaignFetched: false,
      acceptedAppIds: [],
      isOptinAcceptedModalVisible: false,
      brands: [],
    };
  },
  computed: {
    acceptedCampaign() {
      return this.data.sent
          .concat(this.data.current)
          .find(campaign => campaign.id === this.acceptedCampaignId);
    },
  },
  watch: {
    acceptedCampaign(campaign) {
      if (campaign) {
        this.acceptedCampaignFetched = true;
      }
    },
  },
  methods: {
    init() {
      this.$root.$on("filters_updated", () => {
        if (this.$router.currentRoute.name == "campaigns.page") {
          this.updateData();
        }
      });

      this.updateData();
    },
    goToArchive() {
      this.isBrandsArchive = false;
      this.isArchive = true;
      this.updateData();
    },
    goToBrandsArchive() {
      this.isArchive = false;
      this.isBrandsArchive = true;
      this.updateData();
    },
    goToHome() {
      this.isArchive = false;
      this.isBrandsArchive = false;
      this.updateData();
    },
    updateData() {
      let app_ids = localStorage.getItem("selected_apps");
      try {
        app_ids = JSON.parse(app_ids);
      } catch (e) {
        app_ids = [];
      }

      if (!this.isArchive && !this.isBrandsArchive) {
        this.loading.sent = true;
        this.loading.current = true;
        this.data.sent = [];
        this.data.current = [];

        this.service.campaigns
            .list(app_ids, this.service.campaigns.types.SENT)
            .then((data) => {
              data.map((d) => {
                let appsData = [];
                let newOptins = [];
                d.optins.map((od) => {
                  /*if (
                    appsData.filter((ap) => {
                      return ap === od.applicationId;
                    }).length <= 0
                  ) {
                    newOptins.push(od);
                    appsData.push(od.applicationId);
                  }*/
                  newOptins.push(od);
                  appsData.push(od.application_id);
                });
                d.optins = newOptins;
              });

              this.data.sent = data;

              this.handleCampaignStatusDetail(this.data.sent);
            })
            .finally(() => {
              this.loading.sent = false;
            });

        this.service.campaigns
            .list(app_ids, this.service.campaigns.types.CURRENT)
            .then((data) => {
              data.map((d) => {
                let appsData = [];
                let newOptins = [];
                d.optins.map((od) => {
                  if (
                      appsData.filter((ap) => {
                        return ap === od.application_id;
                      }).length <= 0
                  ) {
                    newOptins.push(od);
                    appsData.push(od.application_id);
                  }
                });
                d.optins = newOptins;
              });

              this.data.current = data;

              // this.handleCampaignStatusDetail(this.data.current);
            })
            .finally(() => {
              this.loading.current = false;
            });
      } else if (this.isArchive) {
        this.loading.rejected = true;
        this.loading.expired = true;
        this.data.rejected = [];
        this.data.expired = {
          page: 1,
          pages: 0,
          data: [],
          allData: [],
        };

        this.service.campaigns
            .list(app_ids, this.service.campaigns.types.REJECTED)
            .then((data) => {
              data.map((d) => {
                let appsData = [];
                let newOptins = [];
                d.optins.map((od) => {
                  if (
                      appsData.filter((ap) => {
                        return ap === od.application_id;
                      }).length <= 0
                  ) {
                    newOptins.push(od);
                    appsData.push(od.application_id);
                  }
                });
                d.optins = newOptins;
              });
              this.data.rejected = data;

              // this.handleCampaignStatusDetail(this.data.rejected);
            })
            .finally(() => {
              this.loading.rejected = false;
            });

        this.service.campaigns
            .list(app_ids, this.service.campaigns.types.EXPIRED)
            .then((data) => {
              data.map((d) => {
                let appsData = [];
                let newOptins = [];
                d.optins.map((od) => {
                  if (
                      appsData.filter((ap) => {
                        return ap === od.application_id;
                      }).length <= 0
                  ) {
                    newOptins.push(od);
                    appsData.push(od.application_id);
                  }
                });
                d.optins = newOptins;
              });
              let start = 0;
              let stop = start + 5;
              this.data.expired.allData = data;
              this.data.expired.data = data.slice(start, stop);
              this.data.expired.pages = Math.ceil(data.length / 5);

              // this.handleCampaignStatusDetail(this.data.expired.allData);
            })
            .finally(() => {
              this.loading.expired = false;
            });
      } else if (this.isBrandsArchive) {
        this.refreshBrands();
      }
    },
    setPage(page, type) {
      let all = this.data[type].allData;
      let start = page - 1;
      let stop = page + 5;
      this.data[type].page = page;
      this.data[type].data = all.slice(start, stop);
    },
    rangePage(page, range, type) {
      let start = page - Math.floor(range / 2);

      if (start == -2) {
        start = Math.floor(range / 2) - 2;
      }
      if (start == 0 || start == -1) {
        start = 1;
      }

      let stop = start + range;
      if (stop > this.data[type].pages) {
        stop = this.data[type].pages;
      }

      let result = [];
      for (let i = start; i < stop; i++) {
        result.push(i);
      }

      return result;
    },
    handleCampaignStatusDetail(campaigns) {
      const campaignId = this.$route.query.statusDetail;

      if (campaignId) {
        const campaignToOpenDetailOf = campaigns.find(campaign => campaign.id === campaignId);

        if (campaignToOpenDetailOf) {
          this.campaignSelected = campaignToOpenDetailOf;
        }
      }
    },
    clearCampaignStatusDetail() {
      if (this.$route.query.statusDetail) {
        this.$router.push({name: 'campaigns.page'});
      }
    },
    addBrandToAutoAcceptAndCloseModal(brand) {
      this.service.publisher.autoAcceptBrand(brand)
          .then(() => {
            this.refreshBrands();
          });

      this.isOptinAcceptedModalVisible = false;
    },
    removeBrand(brand) {
      this.service.publisher.removeBrand(brand.brand)
          .then(() => {
            this.refreshBrands();
          });
    },
    refreshBrands() {
      this.loading.brands = true;

      this.service.publisher.getBrands()
          .then(response => {
            this.brands = response;
            this.loading.brands = false;
          });
    },
    isCampaignInSentCampaigns(campaign) {
      return this.data.sent.includes(campaign);
    },
    handleCampaignConfirmModalClose(acceptedApps) {
      const isCampaignSent = this.isCampaignInSentCampaigns(this.campaignSelected);

      this.clearCampaignStatusDetail();
      this.updateData();

      if (isCampaignSent && acceptedApps && acceptedApps.length > 0) {
        this.acceptedCampaignId = this.campaignSelected.id;
        this.acceptedAppIds = acceptedApps;
        this.isOptinAcceptedModalVisible = true;
      }

      this.campaignSelected = null;
    },
  },
};
</script>
<style lang="scss">
.campaign {
  &--pagination {
    float: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: var(--gutter);

    .number {
      display: flex;
      align-items: center;
      justify-content: center;
      background: none;
      width: 35px;
      height: 35px;
      border: 1px solid $grey;
      border-radius: 100%;
      color: $black;
      transition: 240ms linear;
      margin: 0 2px;

      &.current {
        border: 0;
        background: $orange;
        color: $white;
      }

      &:hover {
        background: $grey;
      }
    }

    .control {
      display: block;
      width: 30px;
      height: 30px;
      margin: 0 10px;
      position: relative;
      border: 0;
      background: none;

      .arrow {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        background: $black;
        width: 20px;
        height: 2px;
        transform: translate(-50%, -50%);

        &:before,
        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          background: $black;
          width: 8px;
          height: 2px;
          transform-origin: left center;
        }

        &:before {
          transform: rotate(-45deg);
        }

        &:after {
          transform: rotate(45deg);
        }
      }

      &.next {
        .arrow {

          &:before,
          &:after {
            content: "";
            left: auto;
            right: 0;
            transform-origin: right center;
          }
        }
      }
    }
  }

  &--table {
    display: block;
    width: 100%;
  }

  &--head,
  &--row {
    display: flex;
    width: 100%;
    height: 50px;
    align-items: center;
    padding: 0 10px;
    justify-content: space-between;
  }

  &--row {
    height: 60px;

    &:nth-child(odd) {
      background: $isabelline;
    }
  }

  &--head {
    height: 40px;
  }

  &--col {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: calc((90% / 4) - 2px);
    height: 100%;
    font-weight: 700;
    font-size: 0.8rem;
    color: $dark_grey;
    text-transform: uppercase;
    text-align: left;

    &.action {
      width: calc(5% - 2px);
      justify-content: center;
    }

    .detail,
    .status {
      &--button {
        display: block;
        width: auto;
        height: auto;
        border: 1px solid $grey;
        border-radius: 6px;
        transition: 240ms linear;
        font-weight: 700;
        font-size: 0.8rem;
        line-height: 1;
        text-transform: uppercase;
        padding: 6px 12px;
        cursor: pointer;

        &:hover {
          background: $orange;
          color: $white;
        }
      }

      &--icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
        border: 1px solid $grey;
        border-radius: 100%;
        cursor: pointer;
        transition: transform 230ms ease;

        .dot {
          display: block;
          background: $orange;
          width: 5px;
          height: 5px;
          border-radius: 100%;
          margin: 0 2px;
        }

        svg {
          display: block;
          width: 20px;
          height: 20px;
          fill: $orange;
        }

        &:hover {
          transform: rotate(270deg);
        }

        &.accepted {
          position: relative;

          &:before,
          &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            background: $green;
          }

          &:before {
            width: 35%;
            height: 4px;
            transform: translate(-50%, -50%) rotate(45deg);
            margin-left: -5px;
            margin-top: 2px;
          }

          &:after {
            width: 50%;
            height: 4px;
            transform: translate(-50%, -50%) rotate(-45deg);
            margin-left: 3px;
          }

          &:hover {
            transform: rotate(360deg);
          }
        }
      }
    }
  }

  &--body {
    .campaign--col {
      font-weight: 400;
      text-transform: none;
      font-size: 1rem;
    }
  }
}
</style>
